import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'

// Components
import SignupInput from '@/components/atoms/SignupInput'
import Grid, { Col } from '@/components/meta/Grid'
import ButtonLink from '@/components/atoms/ButtonLink'
import ShopIllustrationGrayscale from './ShopIllustrationGrayscale'

// Helpers / constants
import { EMAIL_PATTERN } from '@/constants/global'
import { getAllActiveCareers } from '@/lib/api'
import { useMediaQuery } from '@/hooks'

// Styling
import styles from './Footer.module.scss'

// Types
import type { SubmitHandler } from 'react-hook-form'
import { IconRotate } from '@/lib/enums'

export interface ILink {
  href: string
  title: string
  items?: ILink[]
}

interface SitemapSection {
  title: string
  href: string
  items?: ILink[]
}

export interface FooterProps {
  sitemap: SitemapSection[]
  channels: ILink[]
  isCompact?: boolean
}

const Footer: React.FC<FooterProps> = ({
  isCompact,
  channels,
  sitemap
}) => {
  const [masked, setMasked] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [careers, setCareers] = useState<number>(0)

  const isTablet = useMediaQuery('(max-width: 768px)')

  // Intersection observer
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  })

  useEffect(() => {
    if (inView) {
      setMasked(true)
    }
    async function getActiveCareers() {
      const jobOpenings = await getAllActiveCareers()
      setCareers(jobOpenings.openings.length)
    }

    getActiveCareers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, setCareers])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IFormValues>()

  const onSubmit: SubmitHandler<IFormValues> = async(data) => {
    // Add email to newsletter
    try {
      await fetch(`https://faas-ams3-2a2df116.doserverless.co/api/v1/web/fn-b55c86d1-426e-4968-b245-be4a3ef674ef/mailList/subscribe-beehiiv?email=${data.email}`, {
        method: 'POST'
      })
        .then(res => res.json())
        .then(() => {
          setIsSubmitted(true)

          reset()

          setTimeout(() => {
            setIsSubmitted(false)
          }, 4000)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <footer
      className={`
        ${styles.footer}
        ${styles.main}
      `}
      ref={ref}
    >
      <div className={`
        ${styles.content}
        ${masked ? styles.masked : ''}
        ${isCompact ? styles.compact : ''}
      `}
      >
        <Grid
          className={`
            ${styles.inner}
            ${masked ? styles.fade : ''}
            ${isCompact ? styles.compact : ''}
          `}
          cols={12}
          size="l"
        >
          <Col className={styles['sitemap-container']} span={6}>
            <section className={styles['sitemap-inner']}>

              <span className={styles['logo-container']}>
                <Link href="/" prefetch={false} scroll={false}>
                  <a>
                    <img
                      alt=""
                      className={styles.logo}
                      src="/img/logo/logo-mark-white.svg"
                    />
                  </a>
                </Link>
              </span>

              <ul className={styles.sitemap}>
                { sitemap.map((item, index) => (
                  <li key={`item-${index}`}>
                    <div>
                      <Link href={item.href} scroll={false}>
                        <a className="underline body-1-xl">{item.title}</a>
                      </Link>
                    </div>
                    {item.items && (
                      <ul className={styles['sitemap-children']}>
                        {item.items.map((subItem, subIndex) => {
                          return subItem.title === 'Careers' && careers !== 0
                            ? (
                              <li className="body-l" key={`subItem-${subIndex}`}>
                                <Link href={subItem.href} scroll={false}>
                                  <a className={`underline ${styles['career-container']}`}>
                                    <span className={`${styles.career}`}>
                                      {subItem.title}
                                    </span>
                                    <span className={`${styles['career-count']} heading-m`}>
                                      {careers}
                                    </span>
                                  </a>
                                </Link>
                              </li>
                            )
                            : (
                              <li className="body-l" key={`subItem-${subIndex}`}>
                                <Link href={subItem.href} scroll={false}>
                                  <a className="underline">{subItem.title}</a>
                                </Link>
                              </li>
                            )
                        })}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </section>

            <section className={styles['mobile-socials']}>
              <ul className={styles.channels}>
                { channels.map((channel, index) => (
                  <li className={styles.channel} key={`channel-${index}`}>
                    <ButtonLink
                      hasNegativeHover
                      href={channel.href}
                      icon={'arrow'}
                      iconDirection={IconRotate.NorthEast}
                      iconPosition="right"
                      size="m"
                    >{channel.title}</ButtonLink>
                  </li>
                ))}
              </ul>
            </section>

          </Col>

          {!isTablet || !isCompact
            ? (
              <Col className={styles.right} span={6}>
                <section className={styles.newsletter}>
                  <p className="heading-1-xl">
                    Sign up for our newsletter
                  </p>

                  <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <SignupInput
                      autoComplete="email"
                      error={
                        errors.email?.type === 'required' ? 'Please enter your email' : 'Please make sure your email contains an @ and a valid domain'
                      }
                      hasError={errors.email !== undefined}
                      hasSuccess={isSubmitted && !errors.email}
                      id="input-email"
                      label="Email:"
                      name="email"
                      placeholder="john@gmail.com"
                      register={register}
                      rules={{
                        pattern: EMAIL_PATTERN
                      }}
                      success="Email subscribed successfully!"
                    />
                  </form>
                </section>

                <ul className={styles.channels}>
                  { channels.map((channel, index) => (
                    <li className={styles.channel} key={`channel-${index}`}>
                      <ButtonLink
                        hasNegativeHover
                        href={channel.href}
                        icon={'arrow'}
                        iconDirection={IconRotate.NorthEast}
                        iconPosition="right"
                        size="m"
                      >
                        {channel.title}
                      </ButtonLink>
                    </li>
                  ))}
                </ul>

              </Col>
            )
            : null}

          <Col className={styles['sub-footer']} span={12}>
            <ul className={styles['sub-footer-links']}>
              <li>
                <Link href="/industries" scroll={false}>
                  <a className="underline body-m">
                      Industries
                  </a>
                </Link>
              </li>
              <li>
                <span className="body-m">&bull;</span>
              </li>
              <li>
                <Link href="/legal/privacy-policy" scroll={false}>
                  <a className="underline body-m">
                      Privacy
                  </a>
                </Link>
              </li>
            </ul>

            <div
              className={styles['copyright-notice']}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <p className="body-s">
                  Copyright 2009-{new Date().getFullYear()} &copy; Yummygum&reg;
              </p>
              <p className={styles.bullet}>&bull;</p>
              <address className="body-s">Amsterdam, The Netherlands</address>
              <ShopIllustrationGrayscale hovering={isHovering} />
            </div>
          </Col>
        </Grid>
      </div>
    </footer>
  )
}

export default Footer
